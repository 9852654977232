// PlayItSayIt.jsx

import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";

const PlayItSayIt = ({ user }) => {
  const { videoId } = useParams();
  const location = useLocation();
  const videoTitle = location.state?.videoTitle;

  const [audioFiles, setAudioFiles] = useState([]);
  const [expandedChunks, setExpandedChunks] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  // States for recording
  const [isRecording, setIsRecording] = useState(false);
  const [currentRecordingChunk, setCurrentRecordingChunk] = useState(null);
  const [recordedAudios, setRecordedAudios] = useState({}); // file_name -> recorded audio URL
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [recordCounts, setRecordCounts] = useState({});

  useEffect(() => {
    if (!videoId) {
      setErrorMsg("No video selected. Please go back and select a video.");
      console.error("Error: No videoId provided.");
      return;
    }

    if (!user?.email) {
      setErrorMsg("User email is not available. Please log in again.");
      console.error("Error: User email is not available.");
      return;
    }

    const fetchVideoDetails = async () => {
      try {
        const response = await fetch(
          `https://api.alindor.online/video/${videoId}?user_email=${encodeURIComponent(
            user.email
          )}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch video details! Status: ${response.status}`
          );
        }

        const data = await response.json();

        if (data.status === "success") {
          const { firebase_folder_link, chunks } = data.video_data;

          if (!firebase_folder_link || !chunks || !Array.isArray(chunks)) {
            throw new Error("Invalid data format received from server.");
          }

          const sortedChunks = chunks
            .filter((chunk) => {
              const isValid =
                chunk &&
                chunk.file_name &&
                chunk.file_name.match(/chunk_(\d+)_/);
              if (!isValid) {
                console.warn("Invalid chunk encountered and skipped:", chunk);
              }
              return isValid;
            })
            .sort((a, b) => {
              try {
                const aIndex = parseInt(
                  a.file_name.match(/chunk_(\d+)_/)[1],
                  10
                );
                const bIndex = parseInt(
                  b.file_name.match(/chunk_(\d+)_/)[1],
                  10
                );
                return aIndex - bIndex;
              } catch (err) {
                console.error("Error sorting chunks:", err);
                return 0;
              }
            })
            .map((chunk, index) => ({
              url: `${firebase_folder_link}/${chunk.file_name}`,
              displayName: `Chunk ${index + 1}`,
              file_name: chunk.file_name,
              subtitle: chunk.text || "No subtitle available",
            }));

          setAudioFiles(sortedChunks);
        } else {
          throw new Error(data.message || "Failed to load video details.");
        }
      } catch (error) {
        console.error("Error fetching video details:", error);
        setErrorMsg("Failed to load video details. Please try again.");
      }
    };

    fetchVideoDetails();
  }, [videoId, user]);

  const toggleExpand = (fileName) => {
    setExpandedChunks((prev) => ({
      ...prev,
      [fileName]: !prev[fileName],
    }));
  };

  const handleRecording = async (fileName, videoTitle) => {
    if (isRecording && currentRecordingChunk === fileName) {
      console.log(`Stopping recording for ${fileName}`);
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setCurrentRecordingChunk(null);
    } else {
      if (isRecording) return;

      try {
        console.log(`Starting recording for ${fileName}`);
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const recorder = new MediaRecorder(stream);
        chunksRef.current = [];

        recorder.ondataavailable = (e) => {
          chunksRef.current.push(e.data);
        };

        recorder.onstop = async () => {
          const blob = new Blob(chunksRef.current, { type: "audio/webm" });

          console.log(`Blob created for ${fileName}:`, blob);

          const safeVideoTitle = (videoTitle || "default_video").replace(
            /[^a-zA-Z0-9-_]/g,
            "_"
          );

          const auth = getAuth();
          const user = auth.currentUser;

          if (!user) {
            console.error("User is not authenticated.");
            alert("You must be logged in to upload recordings.");
            return;
          }

          const uid = user.uid;
          const recordCount = (recordCounts[fileName] || 0) + 1; // Increment record count
          const recordedFileName = `${fileName}_record_${recordCount}.webm`;
          const storagePath = `users/${uid}/${safeVideoTitle}/records/${recordedFileName}`;

          console.log(`Uploading to Firebase path: ${storagePath}`);

          const storage = getStorage();
          const firebaseRef = ref(storage, storagePath);

          try {
            await uploadBytes(firebaseRef, blob);
            const downloadURL = await getDownloadURL(firebaseRef);

            console.log(`Successfully uploaded to: ${downloadURL}`);

            setRecordedAudios((prev) => ({
              ...prev,
              [fileName]: downloadURL,
            }));

            setRecordCounts((prev) => ({
              ...prev,
              [fileName]: recordCount,
            }));

            console.log(`Updated recordedAudios state:`, {
              ...recordedAudios,
              [fileName]: downloadURL,
            });
          } catch (error) {
            console.error("Error uploading to Firebase:", error);
            alert(
              "Failed to upload the recording. Please check your Firebase setup."
            );
          }

          chunksRef.current = [];
        };

        recorder.start();
        mediaRecorderRef.current = recorder;
        setIsRecording(true);
        setCurrentRecordingChunk(fileName);
      } catch (error) {
        console.error("Error accessing microphone:", error);
        alert("Could not access microphone. Please check permissions.");
      }
    }
  };

  const handleDelete = async (fileName) => {
    try {
      const url = `https://api.alindor.online/video/${videoId}/chunk/${fileName}?user_email=${encodeURIComponent(
        user.email
      )}`;

      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete chunk.");
      }

      const result = await response.json();
      console.log(`Chunk ${fileName} marked as deleted:`, result);

      // Update the local state to reflect the deletion
      setAudioFiles((prevFiles) =>
        prevFiles.filter((file) => file.file_name !== fileName)
      );
    } catch (error) {
      console.error("Error deleting chunk:", error);
      setErrorMsg("Failed to delete audio chunk. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center py-10">
      <h1 className="text-4xl font-bold mb-6">Play It & Say It</h1>

      {errorMsg && <p className="text-red-500">{errorMsg}</p>}

      {videoTitle && (
        <h2 className="text-2xl font-semibold mb-4">
          {decodeURIComponent(videoTitle)}
        </h2>
      )}

      {audioFiles.length === 0 && !errorMsg ? (
        <p className="text-lg text-gray-700">
          No audio files available to play for this video.
        </p>
      ) : (
        <div className="space-y-4 w-full max-w-2xl">
          {audioFiles.map((file) => (
            <div
              key={file.file_name}
              className="bg-white shadow rounded-md p-4"
            >
              <div className="flex items-center mb-2">
                <p className="text-gray-700 flex-1 truncate font-semibold">
                  {file.displayName}
                </p>
                <audio controls className="flex-none mr-4">
                  <source src={file.url} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                <button
                  onClick={() => toggleExpand(file.file_name)}
                  className="p-2 text-blue-500 hover:text-blue-700"
                  title="Toggle Subtitle"
                >
                  {expandedChunks[file.file_name] ? "▼" : "▶"}
                </button>
                <button
                  onClick={() => handleDelete(file.file_name)}
                  className="p-2 text-red-500 hover:text-red-700"
                  title="Delete this chunk"
                >
                  &#9746;
                </button>
              </div>

              {expandedChunks[file.file_name] && (
                <p className="text-gray-500 mb-2">{file.subtitle}</p>
              )}

              <div className="flex items-center space-x-4">
                <button
                  onClick={() => handleRecording(file.file_name)}
                  className={`px-4 py-2 rounded ${
                    isRecording && currentRecordingChunk === file.file_name
                      ? "bg-red-500 text-white hover:bg-red-600"
                      : "bg-green-500 text-white hover:bg-green-600"
                  }`}
                >
                  {isRecording && currentRecordingChunk === file.file_name
                    ? "Stop"
                    : "Repeat"}
                </button>
                {recordedAudios[file.file_name] && (
                  <audio
                    key={recordedAudios[file.file_name]} // Force re-render with new key
                    controls
                    src={recordedAudios[file.file_name]} // Use the direct Firebase link
                    onLoadedData={() =>
                      console.log(
                        `Audio loaded successfully for ${file.file_name}: ${
                          recordedAudios[file.file_name]
                        }`
                      )
                    }
                    onError={(e) => {
                      console.error(
                        `Error loading audio for ${file.file_name}:`,
                        e
                      );
                      console.error(
                        `Audio src: ${recordedAudios[file.file_name]}`
                      );
                    }}
                  >
                    Your browser does not support the audio element.
                  </audio>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlayItSayIt;
