// src/firebase.js
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword as firebaseSignInWithEmail, createUserWithEmailAndPassword as firebaseCreateUserWithEmail, sendEmailVerification, sendPasswordResetEmail } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCC-LiWkeZmOziHZaBzOsLI000a-F2kPo4",
    authDomain: "playitsayit-6d080.firebaseapp.com",
    projectId: "playitsayit-6d080",
    storageBucket: "playitsayit-6d080",
    messagingSenderId: "431928748522",
    appId: "1:431928748522:web:fe50e83c0590aa11fbea93"
  };

export const FireBase = firebase.initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
    prompt: "select_account "
});

export const database = getFirestore(FireBase);
export const storage = firebase.storage();
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithEmailAndPassword = (email, password) => firebaseSignInWithEmail(auth, email, password);
export const createUserWithEmailAndPassword = (email, password) => firebaseCreateUserWithEmail(auth, email, password);
export const sendVerificationEmail = (user) => sendEmailVerification(user);
export const sendPasswordReset = (email) => sendPasswordResetEmail(auth, email);
