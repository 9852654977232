// src/App.js

import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { auth } from "./firebase";
import Main from "./pages/Main";
import PlayItSayIt from "./pages/PlayItSayIt"; // Import the PlayItSayIt component

export const AppContext = createContext();

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((currentUser) => {
            setUser(currentUser);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // or any loading component
    }

    return (
        <GoogleOAuthProvider clientId="431928748522-sic3g172lkavp9kd0revcplenosfpurd.apps.googleusercontent.com">
            <AppContext.Provider value={{ user, setUser }}>
                <BrowserRouter>
                    <Routes>
                        {/* Main page for signing in and managing video links */}
                        <Route path="/" element={<Main setUser={setUser} user={user} />} />
                        
                        {/* PlayItSayIt page to view and interact with audio chunks */}
                        {/* Ensure the user is authenticated */}
                        {user ? (
                            <Route path="/play-it-say-it/:videoId" element={<PlayItSayIt user={user} />} />
                        ) : (
                            <Route path="/play-it-say-it/*" element={<Navigate to="/" />} />
                        )}

                        {/* If the user is not authenticated, redirect them to the main page */}
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </BrowserRouter>
            </AppContext.Provider>
        </GoogleOAuthProvider>
    );
}

export default App;
